@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body, html, #root {
    height: 100%;
    margin: 0;
    font-family: 'Poppins', sans-serif;
}

.new-details {
    display: flex;
    align-items: center;  /* Vertically center the items */
    justify-content: space-between; /* Distribute space evenly */
    padding: 10px;
    background-color: #f8f8f8;
    border-radius: 10px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.new-info {
    display: flex;
    align-items: center;
    gap: 15px; /* Add space between image and text */
}
.new-button {
    font-size: 24px;
    border: none;
    background: none;
    cursor: pointer;
    margin-left: auto;
    padding-left: 20px;
}
