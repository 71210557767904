@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

/* General Styles */
body, html, #root {
    height: 100%;
    margin: 0;
    font-family: 'Poppins', sans-serif;
}

/* Map Container */
.map-container {
    height: 100vh;
    width: 100vw;
    position: relative;
}

/* Search Bar */
.search-bar {
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    max-width: 400px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1000; 
    background-color: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    margin-left: 0px;
}

/* Brands Section */
.brands-section {
    padding: 20px;
}

.brands-container {
    display: flex;
    /* justify-content: space-between;  */
    margin-bottom: 20px;
    gap: 15px; 
    overflow-x: auto;
    scrollbar-width: none;
}

.brands-container img,
.brand-item img {
    height: 80px;
    width: 80px;
    object-fit: contain;
    border-radius: 10px;
}

.brand-item {
    text-align: center;
}

.brand-item p {
    margin-top: 10px;
    font-size: 14px;
}

/* Full Width Image */
.full-width-image-container {
    margin-top: 20px;
    text-align: center;
}

.full-width-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    object-fit: cover;
}

/* Suggestions Dropdown */
.suggestions-dropdown {
    position: absolute;
    width: 80%;
    max-height: 200px;
    overflow-y: auto;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
    top: 60px; /* Adjust based on your layout */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.suggestion-item {
    padding: 10px;
    cursor: pointer;
}

.suggestion-item:hover {
    background-color: #f0f0f0;
}

/* Dropdown Content */
.dropdown-content {
    display: block;
    position: absolute;
    min-width: 100vw;
    z-index: 1;
    top: 8%;
    background-color: white;
}

.drop {
    padding: 5px 17px;
}

/* Search Bar Container */
.search-bar-container {
    position: fixed;
    top: 14px;
    left: 0px;
    z-index: 100;
    height: 80px;
    width: 100vw;
    background-color: transparent;
    display: flex;
    justify-content: center; /* Centers horizontally */
    align-items: center; /* Centers vertically */
}

.child {
    
    font-family: 'Poppins';
    width: 80vw;
}
