@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

body {
  font-family: 'Poppins', sans-serif;
  overflow-x:hidden ;
}

.offers-page {
  padding: 16px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.back-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-right: 100%;
}

.favorite-button {
  position: absolute;
  top: 0;
  right: 35px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 24px;
  padding: 8px;
  display: flex;
  align-items: center;
}

.restaurant-info {
  text-align: center;
  margin-top: 0px;
  margin-left: 10px;
}

.restaurant-logo {
  width: 100px;
  height: auto;
  margin-bottom: -40px;
}

.address {
  color: #888;
  font-size: 14px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis; 
}

.offers-section {
  margin-top: 24px;
}

.offers-section h3 {
  margin-bottom: 16px;
  font-size: 18px;
}

.offer-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: -20px;
  padding: 8px;
  border-radius: 8px;
  margin-left: -3px;
  margin-bottom: 2px;
}

.offer-image {
  width: 110px;
  height: 105px;
  border-radius: 8px;
  margin-right: 16px;
}

.offer-details h4 {
  margin: 0;
  font-size: 25px;
  margin-bottom: 20px;
  margin-left: 10px;
}

.offer-details p {
  margin: -20px 1px 1px 5px; /* Adjust the value as needed */
  color: black;
  font-size: 15px; /* Increased font size */
  font-weight: 700; /* Bold weight */
  margin-bottom: 0px;
  white-space: nowrap;
}


.offerp{
margin-bottom: 5px;
}

.bus{
  margin-top: 60px;
}
.back-button-image {
  width: 25px;
  height: 30px; 
}
