/* Container for the sidebar and the favorites content */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
body, html, #root {
    font-family: 'Poppins', sans-serif;
    width: 100vw;
    margin: 0; /* Ensure there's no default margin */
}
@media (max-width: 768px) {
    .image {
        height: 30vh; /* Smaller height for smaller screens */
        width: 100vw;  /* Full width for smaller screens */
    }
}

.favorites-container {
    display: flex;
    height: 90vh;
    /* border: 1px solid  red; */
    overflow: hidden;
    flex-direction: column;
    position: relative;
    padding: 20px;
}

.sidebar-container {
    position: absolute;
    left: -280px;
    top: 0;
    height: 100%;
    z-index: 1000;
    transition: left 0.3s ease;
}

.sidebar-container.open {
    left: 0;
}

.menu-icon {
    position: fixed;
    top: 20px;
    left: 20px;
    z-index: 1001;
    cursor: pointer;
    width: 30px;  /* Adjust the width of the icon */
    height: 30px; /* Adjust the height of the icon */
    padding: 0;   /* Remove padding to avoid unnecessary enlargement */
    border-radius: 50%;
    background-image: url('./Group 1171275657.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat; /* Ensure the background image does not repeat */
}

.menu-icon img {
    width: 100%; /* Make sure the image fills the container */
    height: auto; /* Maintain aspect ratio */
    border-radius: 50%; /* Make the icon round */
}

/* Adjustments for favorites content */
.favorites-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: auto; /* Allow scrolling if content overflows */
    padding: 10px;
    transition: margin-left 0.3s ease;
}

.favorites-content.shifted {
    margin-left: 0;
}

/* Favorites list styling */
.favorites-list {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 20px;
}

.favorite-item {
    display: flex;
    align-items: center;
    /* background-color: white; */
    border-radius: 12px;
    /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1); */
    overflow: hidden;
    width: 95%; /* Full width */
    height: 100px;
    margin-top: 10px;
    /* border:1px solid red; */
    transition: transform 0.2s ease;
}

.favorite-item:hover {
    transform: translateY(-5px);
}

.favorite-image {
    width: 72px;
    height: 72px;
    border-radius: 10px;
    /* border: 1px solid red; */
    object-fit: cover;
    margin-right: 15px;
}

.favorite-details {
    display: flex;
    flex-direction: column;
    position: relative;
}

.favorite-details h3 {
    margin: 1;
    font-size: 18px;
    color: #333;
    justify-content: center;
    margin-bottom:25px ;
    margin-left: 30px;
}

.favorite-details  {
    margin: 5px 0 0;
    font-size: 14px;
    width: 70%;
    height: 90px;
    color: #777;
 
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.2;
    /* border: 1px  solid red;  */
    padding: 10px 10px;/* Adjust this based on your font size */
    
}
#default-avatar12 {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #cccccc; /* Default background color */
    color: #ffffff; /* Text color */
    border-radius: 5%;
    font-size: 40px;
    font-weight: bold;
  }

.favorite-heart {
    font-size: 24px;
    color: #e74c3c;
    cursor: pointer;
}

.favorite-heart.selected span {
    color: red; /* Change color to indicate selection */
}

.subTitle {
    text-align: center;
    padding: 15px 0px;
    font-weight: 400;
    font-size: 14px;
}

/* Center .no-favorites */
.no-favorites {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%; /* Ensure it takes up full height of the parent */
    color: #555;

    /* border: 0px solid transparent; */
}
.image {
    height: 50%; /* Adjust the height of the container as needed */
    width: 100%;  /* Adjust the width of the container as needed */
    background-image: url("../noData.png");
    background-repeat: no-repeat;
    background-size: 80%; /* Adjust the percentage to make the image smaller */
    background-position: center; /* Center the image within the container */
}



.favorite-address {
    font-size: 14px;
    color: #777;
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
    padding-right: 40px;
    padding-top: 10px; /* Ensure there's space for the toggle arrow */
}
.favorite-address.collapsed {
    max-height: 3.6em; /* Height for 2 lines */
}

.favorite-address.expanded {
    max-height: 500px; /* Large enough height to show full content */
    padding-right: 40px; /* Keep space for the arrow */
}

.expand-toggle {
    position: absolute;
    top: 10px; /* Adjust as needed */
    right: 10px; /* Adjust as needed */
    cursor: pointer;
    font-size: 16px;
    color: #007bff;
    background: none;
    border: none;
}