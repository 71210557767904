.rootParent{
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 120px; 
  /* height: 150px; */
  /* border: 1px solid red; */
  text-overflow: ellipsis;

}


.favorite-card__button {
  border: none;
  background: none;
  cursor: pointer;
  position: absolute;
  top: 10px; /* Position at the top */
  right: 10px;  /* Position at the right */
  padding: 5px; 
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;  /* Adjust the size of the circle */
  height: 30px; /* Adjust the size of the circle */
  background-color: rgba(255, 255, 255, 0.768); /* White background for the circle */
  border-radius: 50%; /* Make the button circular */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: add some shadow for a 3D effect */
  margin-top: 10px;
}

.favorite-card {
  cursor: pointer; 
  height: 140px; /* Adjusted height */
  width: 120px;  /* Adjusted width */
  border-radius: 10%;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  position: relative; /* To position the button absolutely */
}

.favorite-card__image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 8%;
}

.favorite-card__default-avatar {
  width: 120px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: bold;
  border-radius: 8%;
  background-color: #ffffff;
  color: rgb(255, 255, 255);
  position: relative; /* To position the button absolutely */
}

.favorite-card__heart {
  font-size: 18px; /* Adjust the size of the heart */
  color: rgb(128, 128, 128);
  /* border: 1px solid red; */
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.favorite-card__heart--filled {
  color: rgb(255, 21, 56);
}
