/* Sidebar Container */
.sidebar {
    width: 330px;
    height: 98%;
    background-color: #ffffff;
    padding: 30px 20px;

    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    font-family: 'Poppins', sans-serif;
}

/* Profile Section */
.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 50px;
}

#avatar12 {
    width:80px;
    height: 80px;
    border-radius: 50%;
    object-fit: contain;
    margin-bottom: 8px;
    /* border: 1px solid red; */
    /* overflow: visible; */
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    position: relative;
}

.name {
    font-size: 22px;
    color: #2d3436;
    font-weight: 700;
     top: revert-layer; 
}

/* Menu Section */
.menu {
    display: flex;
    flex-direction: column;
}

.menu-item {
    display: flex;
    align-items: center;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 12px;
    text-decoration: none;
    color: #2d3436;
    font-size: 18px;
    font-weight: 500;
    background-color: #f8f9fd;
    transition: all 0.3s ease;
    box-shadow: 0 4px 12px rgba(49, 49, 49, 0.279);
    position: relative;
}

.menu-item:hover {
    background-color: #e9effd;
    transform: translateX(10px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.icon {
    width: 16px;
    height: 16px;
    color: #0984e3;
    margin-right: 15px;
    background-repeat: no-repeat;
    filter: brightness(0) invert(0.6);
}

/* Example for different icons */
.icon.home {
    background-image: url('./1111.png');
    background-size: contain;
     /* Replace with your home icon URL */
}

.icon.reward {
    background-image: url('./1112.png'); 
    background-size: contain;/* Replace with your reward icon URL */
}

.icon.favorites {
    background-image: url('./1113.png');
    background-size: contain; /* Replace with your favorites icon URL */
}

.icon.contact {
    background-image: url('./1114.png');
    background-size: contain; /* Replace with your contact icon URL */
}

.icon.privacy {
    background-image: url('./1115.png'); 
    background-size: contain;/* Replace with your privacy icon URL */
}

.text {
    flex-grow: 1;
}

/* Optional: Add a gradient highlight to active item */
.menu-item.active {
    background: linear-gradient(45deg, #0984e3, #74b9ff);
    color: #fff;
    box-shadow: 0 8px 24px rgba(9, 132, 227, 0.4);
}

.menu-item.active .icon {
    filter: brightness(100) invert(1);
}

@media (max-width: 768px) {
    .sidebar {
        width: 240px;
        padding: 20px;
    }

    .menu-item {
        font-size: 16px;
        padding: 12px;
    }

    .avatar {
        width: 70px;
        height: 70px;
    }

    .name {
        font-size: 20px;
        text-align: center;
        left: 8px;
    }
}
