.reward-history {
    padding: 20px;
    font-family: 'Poppins', sans-serif;
}

.header {
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0px;
}

.menu-btn {
    position: absolute;
    top: 15px;
    left: 15px;
    background: none;
    border: none;
    font-size: 24px;
}


h1 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    position: absolute;
    top:0px;
    
}
.hd{
    /* border:1px solid red; */
    height:20px;
    display: flex;
    align-items: center;
    padding: 4px 0px;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
}

p {
    margin: 0px;
    font-size: 14px;
    color: #777;
    
}
.subTitle{
    text-align: center;
    padding: 0px 20px;
    font-weight: 400;
    font-size: 14px;
}

.rewards-list {
    margin-top: 20px;
}

.reward-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
}

.reward-logo {
    width: 50px;
    height: 50px;
    margin-right: 15px;
    border-radius: 50%;
}

.reward-details {
    flex-grow: 1;
}

.reward-description {
    margin: 0;
    font-size: 16px;
    color: #333;
    font-weight: 500;
}

.reward-date {
    margin: 5px 0 0;
    font-size: 12px;
    color: #999;
}

.expired .reward-description {
    color: #aaa;
}

.expired .reward-logo {
    filter: grayscale(100%);
}



.expired:first-of-type::before {
    content: 'Expired';
    color: #ff4d4d;
    font-weight: bold;
    display: block;
    margin-bottom: 10px;
}
