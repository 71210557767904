@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
body, html, #root {
    font-family: 'Poppins', sans-serif;
  }
  
.thank-you-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f8ff; 
  position: relative;
  overflow: hidden;
}
.hh{
  font-size: 30px;
  font-weight: 600;
}
.sub{
  font-size: 20px;
  font-weight: 400;
  color: rgba(128, 128, 128, 0.713);
  text-align: center;
}
.thank-you-content {
  text-align: center;
  flex-grow: 1;
 
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  justify-content: center;
}

.thank-you-page h1 {
  font-size: 3em;
  color: #333;
}

.thank-you-page p {
  font-size: 1.5em;
  color: #666;
  margin: 10px 0;
}

.home-button {
  
  padding: 10px 20px;
  font-size: 1.2em;
  color: white;
  background-color: #007bff; 
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 20px;
}

.home-button:hover {
  background-color: #0056b3; 
}
