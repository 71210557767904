/* src/PrivacyPolicy.css */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body, html {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.privacy-policy-container {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    overflow: auto;
     /* Prevents overflow issues */
}

.privacy-policy-header {
    text-align: center;
    margin-bottom: 20px;
}

.privacy-policy-header h1 {
    font-size: 24px;
    color: #333;
}

.privacy-policy-content {
    line-height: 1.6;
    text-align: justify; /* Align text for better readability */
    word-wrap: break-word; /* Ensures long words break and wrap to the next line */
}

.privacy-policy-content section {
    margin-bottom: 20px;
}

.privacy-policy-content h2 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
}

.privacy-policy-content p {
    font-size: 16px;
    color: #555;
    margin: 0; /* Ensure no extra margins */
}

@media (max-width: 768px) {
    .privacy-policy-container {
        padding: 10px;
    }

    .privacy-policy-header h1 {
        font-size: 20px;
        left: 120px;
        top: 10px;
    }

    .privacy-policy-content h2 {
        font-size: 18px;
        text-align: center;
        
    }

    .privacy-policy-content p {
        font-size: 14px;
        text-align: center;
        left: 20px;
        top: 2px;
    }
}
