@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

body, html, #root {
    font-family: 'Poppins', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px;
    background-color: #ffffff;
    position: relative;
}

.imgHead {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

.imgHead-img {
    height: 60px;
    width: 60px;
    margin-bottom: 10px;
}

#imgHead-header {
    color: #505050;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
}

.coupon-card {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 350px;
    max-width: 100%;
    position: relative;
}

.coupon-card:before, .coupon-card:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #ffffff;
    border-radius: 50%;
}

.coupon-card:before {
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 -5px 0 0 #f2f2f2;
}

.coupon-card:after {
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0 5px 0 0 #f2f2f2;
}

.discount {
    font-size: 28px;
    font-weight: bold;
    color: #000000;
    text-align: center;
    margin-bottom: 10px;
}

.coupon-body {
    margin-bottom: 20px;
    line-height: 1.6;
    text-align: left;
    color: #3c3c3c;
}

.coupon-body ul {
    padding-left: 20px;
}

.coupon-body ul li {
    margin-bottom: 10px;
}

.coupon-code {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 22px;
    font-weight: bold;
    padding: 15px 0;
    width: 100%;
    border: 2px dashed #414141;
    border-radius: 10px;
    background-color: #fafafa;
    margin-bottom: 20px;
}

#titleCoupon {
    font-size: 18px;
    font-weight: 500;
    color: #888888;
    margin-bottom: 5px;
}

.coupon-footer {
    text-align: center;
    font-size: 14px;
    color: #6c757d;
}
