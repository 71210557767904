.bottom-sheet {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    box-shadow: 0 -2px 15px rgba(0, 0, 0, 0.2);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    overflow: hidden;
    transition: height 0.4s cubic-bezier(0.25, 0.8, 0.25, 1), 
                box-shadow 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
    z-index: 1000;
    min-height: 30vh;
}

.drag-handle {
    width: 16%;
    height: 4px;
    background-color: rgba(128, 128, 128, 0.746);
    border-radius: 2px;
    margin: 8px auto;
}

.brands-section {
    width: 100vw;
}

.brands-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 10px;
    padding: 0 20px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scroll-behavior: smooth; /* Smooth scrolling */
}

.brands-container::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Chrome, Safari, and Opera */
}

.h {

    font-size: 16px;
    font-weight: 600;
    padding: 20px;
}

.bottom-h {
    font-size: 16px;
    font-weight: 500;
    padding: 10px 20px;
}

img {
    width: 100px;
    height: auto;
}

.brand-item {
    width: 100px;
    height: auto;
}

.space {
    width: 20px;
}

.header-fav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px 20px;
}

.see-all-fav-link {
    color: blue;
    cursor: pointer;
    transition: color 0.2s ease-in-out;
}

.see-all-fav-link:hover {
    color: darkblue;
}
