/* src/components/CircularAvatar.css */

.circular-avatar-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    text-align: center;
    /* border: 1px solid red; */
    max-width: 100px;
}

.circular-avatar {
    width: 80px; /* Adjust size as needed */
    height: 80px; /* Adjust size as needed */
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 24px;
    font-weight: bold;
    text-transform: uppercase;
    transition: background-color 0.3s; /* Optional: Smooth transition effect */
}

.circular-avatar img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.avatar-text {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* background: #000; Default background color for text avatar */
}

.business-name {
    width: 100%;
    margin-top: 8px; /* Space between avatar and business name */
    font-size: 14px; /* Adjust size as needed */
    color: #333; /* Adjust color as needed */
    font-weight: 600; /* Optional: To make the name stand out */
    text-align: center;
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    
}
