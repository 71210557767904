@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

/* General Styles */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Poppins', sans-serif;
    box-sizing: border-box;
    background-color: #f5f5f5;
    height: 100%; /* Ensure body and html take full height */
}

/* Container */
.contact-form-container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    max-width: 500px;
    width: 100%;
    min-height: 90vh; /* Full height of the viewport */
    margin: 0 auto; /* Center container horizontally */
    border-radius: 15px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
}

/* Header */
.header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

/* Form */
.form {
    width: 100%;
    height: auto;
    display: flex;
    flex-grow: 1;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* border: 1px solid red; */
}

/* Title */
.title {
    font-size: 2em;
    margin: 0px;
    color: hsl(0, 0%, 20%);
    padding: 0px 0px;
    text-align: center;
}

/* Subtitle */
.subtitle {
    font-size: 1em;
    margin: 0px;
    padding-bottom: 20px;
    color: #777;
    text-align: center;
    top:0px;
}

/* Input Fields */
.input-field,
.textarea-field {
    width: 300px;
    padding: 15px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 1em;
    transition: border-color 0.3s ease;
}

.input-field:focus,
.textarea-field:focus {
    border-color: #007BFF;
    outline: none;
    font-family: 'Poppins';
}

/* Textarea */
.textarea-field {
    resize: vertical;
    min-height: 120px;
    font-size: 1em;
    font-family: 'Poppins';
}

/* Submit Button */
.submit-button {
    width: 90%;
    padding: 15px;
    background-color: #007BFF;
    color: white;
    font-size: 1.2em;
    border: none;
    /* flex-grow: 1; */
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
.button-parent
{
    width: 100%;
    display: flex;
    flex-grow: 1;
    justify-content: end;
    align-items: center;
    flex-direction: column;
}

.submit-button:hover {
    background-color: #0056b3;
}

/* Menu Icon */
.menu-icon {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 20px;
    left: 20px;
    cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 600px) {
    .contact-form-container {
        padding: 15px;
        margin: 0; /* Remove margin on mobile to use full width */
        height: 100vh; /* Full height on mobile */
        box-shadow: none; /* Remove shadow for a cleaner look on small screens */
        border-radius: 0; /* Remove border-radius for a full-width look */
    }

    .title {
        font-size: 1.5em;
    }

    .input-field,
    .textarea-field {
        padding: 12px;
    }

    .submit-button {
        padding: 12px;
        font-size: 1em;
    }
}
.hd{
    /* border:1px solid red; */
    height:20px;
    display: flex;
    align-items: center;
    padding:6px 0px;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
}
